import React, { useState, useEffect, useRef, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import VisibilitySensor from "react-visibility-sensor"
import { motion } from "framer-motion"

import { useOnScreen } from "../../hooks"
import Context from "../../context"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/underlining"
import Button from "../../styles/button"
import Icon from "../../components/icons"
import { lightTheme, darkTheme } from "../../styles/theme"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 6rem;
  .cta-btn {
    display: block;
    text-align: center;
    margin: 2rem auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
      margin: 0 auto;
    }
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
      padding-right: var(--edge-padding);
      padding-left: var(--edge-padding);
    }
    .section-title {
      padding-right: var(--edge-padding);
      padding-left: var(--edge-padding);
      text-align: center;
      margin-bottom: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
        padding-right: 0;
        padding-left: 0;
        text-align: left;
      }
    }
    .projects {
      display: flex;
      flex-direction: column;
      margin-top: -3rem;
      padding: var(--edge-padding);
      &::-webkit-scrollbar {
        display: none;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
        flex-direction: column;
        margin-top: 0;
        padding: 0;
        overflow: visible;
      }
      /* Show scrollbar if desktop and wrapper width > viewport width */
      @media (hover: hover) {
        scrollbar-color: ${({ theme }) => theme.colors.scrollBar} transparent; // Firefox only
        &::-webkit-scrollbar {
          display: block;
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar:horizontal {
          height: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 0.2rem solid ${({ theme }) => theme.colors.background};
          background-color: ${({ theme }) => theme.colors.scrollBar};
        }

        &::-webkit-scrollbar-track {
          background-color: ${({ theme }) => theme.colors.background};
          border-radius: 8px;
        }
      }
    }
  }
`

const StyledProject = styled(motion.div)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  flex-shrink: 0;
  /* gap: 2rem; */
  /* padding-right: var(--edge-padding); */
  /* max-width: 20rem; */
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    max-width: 100%;
    /* padding-right: 5rem; */
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
    justify-content: space-between;
    flex-shrink: 1;
    max-width: 100%;
    margin-bottom: 6rem;
    padding-right: 0;
    /* Positioning of image and details should vary */
    flex-direction: ${({ position }) =>
      position % 2 !== 0 ? "row" : "row-reverse"};
    gap: 2rem;
  }
  .details {
    width: 100%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
      margin-top: 0;
    }
    .category {
      font-size: 0.875rem;
      line-height: 1rem;
      text-transform: uppercase;
      letter-spacing: +1px;
      span {
        margin-right: 0.5em;
        /* margin-left: -1.8em; */
      }
    }
    .title {
      margin-top: 0.8rem;
      margin-bottom: 0.5rem;
      font-size: 1.4rem;
      line-height: 1.625rem;
      font-weight: 700;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.5rem;
      line-height: 1.2rem;
      justify-content: center;
      span {
        margin-right: 1rem;
        margin-bottom: 0.75rem;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.micro}) {
        justify-content: flex-start;
      }
    }
    .links {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-top: 1rem;

      // make link buttons full-width and vertically stacked
      flex-direction: column;
      gap: 1rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.micro}) {
        // make it display normally, as it does now
        flex-direction: row;
        gap: 1.5rem;
      }

      a {
        border-radius: 8px;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.colors.background};
        background: linear-gradient(
          to right,
          ${({ theme }) => theme.colors.primary} 50%,
          ${({ theme }) => theme.colors.background} 50%
        );
        background-size: 205% 100%;
        background-position: right bottom;
        /* border-radius: ${({ theme }) => theme.borderRadius}; */
        border: 1px solid ${({ theme }) => theme.colors.primary};
        transition: all 0.1s ease-out;
        font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primary};
        width: 70%;

        &:hover {
          background-position: left bottom;
          color: ${({ theme }) => theme.colors.background};
        }
        &:hover svg {
          filter: invert(1);
        }

        span {
          margin-top: 2px;
          margin-left: 0.5rem;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.micro}) {
          width: fit-content;
        }
      }
      svg {
        fill: ${({ theme }) => theme.colors.primary};
        width: 1.3rem;
        height: 1.3rem;
      }
    }
    p {
      line-height: 1.65rem;
    }
  }
  .screenshot {
    width: 100%;
    max-width: 35rem;
    height: auto;
    border-radius: 0.25rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-out;
    margin: 0 0 auto 0;
    align-self: center;
    /* border: 1px solid ${({ theme }) => theme.colors.subtext}; */
    &:hover {
      transform: translate3d(0px, -0.125rem, 0px);
      box-shadow: 0 0 var(--edge-padding) rgba(0, 0, 0, 0.2);
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
      height: auto;
      align-self: flex-start;
    }
  }
`

const Projects = ({ content }) => {
  const { darkMode } = useContext(Context).state
  const sectionDetails = content[0].node
  const projects = content.slice(1, content.length)

  // projects don't track the visibility by using the onScreen hook
  // instead they use react-visibility-sensor, therefore their visibility
  // is also stored differently
  const [onScreen, setOnScreen] = useState({})
  const handleOnScreen = el => {
    if (!onScreen[el]) {
      const updatedOnScreen = { ...onScreen }
      updatedOnScreen[el] = true
      setOnScreen(updatedOnScreen)
    }
  }
  const pVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  useEffect(() => {
    // required for animations: set visibility for all projects to
    // "false" initially
    let initial = {}
    projects.forEach(project => {
      initial[project.node.frontmatter.position] = false
    })
    setOnScreen(initial)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Required for animating the title
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef, 0.01)
  const tVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  return (
    <StyledSection id="projects">
      <StyledContentWrapper>
        <motion.div
          ref={tRef}
          variants={tVariants}
          animate={tOnScreen ? "visible" : "hidden"}
        >
          <h3 className="section-title">{sectionDetails.frontmatter.title}</h3>
        </motion.div>
        <div className="projects">
          {projects.map((project, key) => {
            const { body, frontmatter, exports } = project.node
            const { moreInfo } = exports || {}
            //! I DON'T UNDERSTAND WHY THIS DOESN'T WORK
            const hasImage = (
              frontmatter.screenshot?.[0] || frontmatter.screenshot
            )?.childImageSharp?.fluid

            return (
              <VisibilitySensor
                key={key}
                onChange={() => handleOnScreen(key + 1)}
                partialVisibility={true}
                minTopValue={100}
              >
                <StyledProject
                  position={key + 1}
                  variants={pVariants}
                  animate={
                    onScreen[frontmatter.position] ? "visible" : "hidden"
                  }
                >
                  <div className="details">
                    <div className="category">
                      <span>{frontmatter.emoji}</span>
                      {frontmatter.category}
                    </div>
                    <div className="title">{frontmatter.title}</div>
                    <MDXRenderer>{body}</MDXRenderer>
                    <div className="tags">
                      {frontmatter.tags.map(tag => (
                        <Underlining key={tag} highlight>
                          {tag}
                        </Underlining>
                      ))}
                    </div>
                    <div className="links">
                      {frontmatter.external && (
                        <a
                          href={frontmatter.external}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="External Link"
                        >
                          <Icon
                            name="external"
                            color={
                              darkMode
                                ? darkTheme.colors.background
                                : lightTheme.colors.background
                            }
                          />
                          <span>View Demo</span>
                        </a>
                      )}
                      {frontmatter.github && (
                        <a
                          href={frontmatter.github}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="External Link"
                        >
                          <Icon
                            name="github"
                            color={
                              darkMode
                                ? darkTheme.colors.background
                                : lightTheme.colors.background
                            }
                          />
                          <span>Browse Code</span>
                        </a>
                      )}
                      {frontmatter.gallery && (
                        <a
                          href={frontmatter.gallery}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="More Screenshots"
                        >
                          <Icon
                            name="external"
                            color={
                              darkMode
                                ? darkTheme.colors.background
                                : lightTheme.colors.background
                            }
                          />
                          <span>More Screenshots</span>
                        </a>
                      )}
                    </div>
                  </div>
                  {hasImage && (
                    <a
                      href={
                        frontmatter.screenshot?.[0].childImageSharp.fluid.src
                      }
                      aria-label="View full-size screenshot"
                      className="screenshot"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <Img
                        className="screenshot"
                        fluid={
                          (
                            frontmatter.screenshot?.[0] ||
                            frontmatter.screenshot
                          ).childImageSharp.fluid
                        }
                      />
                    </a>
                  )}
                  {moreInfo && (
                    <details>
                      <summary>More Information</summary>
                      {moreInfo.moreMDX && (
                        <MDXRenderer>{moreInfo.moreMDX}</MDXRenderer>
                      )}
                    </details>
                  )}
                </StyledProject>
              </VisibilitySensor>
            )
          })}
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Projects.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
        exports: PropTypes.shape({
          moreInfo: PropTypes.shape({
            moreMDX: PropTypes.string,
            features: PropTypes.arrayOf(PropTypes.string),
          }).isRequired,
        }),
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Projects
