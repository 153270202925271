import React, { useEffect, useRef } from "react"
import SVG from "react-inlinesvg"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { motion, useAnimation } from "framer-motion"

import { MDXRenderer } from "gatsby-plugin-mdx"
import { useOnScreen } from "../../hooks/"
import ContentWrapper from "../../styles/contentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 4rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: var(--edge-padding);
      padding-left: var(--edge-padding);
    }
    .section-title {
      text-align: center;
      @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
        text-align: left;
      }
    }
  }
  .interests-padding {
    padding: 0 var(--edge-padding) 1.25rem var(--edge-padding);
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 0;
    }
  }
`

const StyledInterests = styled.div`
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  max-width: 100%;
  flex-wrap: wrap;
  margin: 1.5rem 0;
  /* Workaround: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout */
  &::after {
    content: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
      content: " ";
      flex-grow: 1000000;
      width: 1px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 15.625rem);
    overflow: visible;
  }
  .interest {
    flex-grow: 1;
    width: fit-content;
    position: relative;
    /* max-width: 15rem; */
    /* min-height: 3rem; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem;
    padding-right: calc(0.75rem * 1.5);
    /* border: 0.125rem solid ${({ theme }) => theme.colors.primary}; */
    border-radius: ${({ theme }) => theme.borderRadius};
    background: ${({ theme }) => theme.colors.card};
    color: ${({ theme }) => theme.colors.primary};
    transition: background-position 0.05s ease-out, transform 0.1s ease-out;

    /* Gradient BG hover stuff - bottom-to-top, like underline element */
    /* background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.card} 50%,
      ${({ theme }) => theme.colors.cardHover} 50%
    );
    background-size: 100% 205%;
    background-position: top;
    &:hover {
      background-position: bottom;
      transform: translateY(-2px) !important;
    } */

    /* Gradient BG hover stuff - left-to-right, like the buttons */
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.cardHover} 50%,
      ${({ theme }) => theme.colors.card} 50%
    );
    background-size: 205% 100%;
    background-position: right bottom;
    &:hover {
      background-position: left bottom;
      transform: translateY(-2px) !important;
    }

    .icon {
      margin-right: 0.75rem;
      max-height: 24px;
      max-width: 24px;
      min-width: 24px;
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .interest {
      padding: 1rem;
      padding-right: calc(1rem * 1.5);
      .icon {
        max-height: 32px;
        max-width: 32px;
        min-width: 32px;
        margin-right: 1rem;
      }
    }
  }
`

const Interests = ({ content }) => {
  const { body, exports, frontmatter } = content[0].node
  const { body: postInterests } = content[1].node
  const { interests } = exports

  const iControls = useAnimation()
  const tRef = useRef()
  const onScreen = useOnScreen(tRef, 0.2)
  const tVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  useEffect(() => {
    const sequence = async () => {
      if (onScreen) {
        // i receives the value of the custom prop - can be used to stagger
        // the animation of each "interest" element
        await iControls.start(i => ({
          y: 0,
          opacity: 1,
          transition: { delay: i * 0.02 },
        }))
      }
    }
    sequence()
  }, [onScreen, iControls])

  const getInterestIcon = icon => {
    if (!icon.childImageSharp && icon.extension === "svg")
      return <SVG className="icon" src={icon.publicURL} fill="inherit" />
    // return <img className="icon" src={icon.publicURL} />
    return <Img className="icon" fixed={icon.childImageSharp.fixed} />
  }

  return (
    <StyledSection id="skills">
      <StyledContentWrapper>
        <motion.div
          ref={tRef}
          variants={tVariants}
          initial={{ opacity: 0 }}
          animate={onScreen ? "visible" : "hidden"}
          className="interests-padding"
        >
          <h3 className="section-title">{frontmatter.title}</h3>
          <MDXRenderer>{body}</MDXRenderer>
          <StyledInterests itemCount={interests.length}>
            {interests.map(({ name, icon }, key) => (
              <motion.div
                className="interest"
                key={key}
                custom={key}
                initial={{ opacity: 0, y: -10 }}
                animate={iControls}
              >
                {getInterestIcon(icon)} {name}
              </motion.div>
            ))}
          </StyledInterests>
          <MDXRenderer>{postInterests}</MDXRenderer>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Interests.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        exports: PropTypes.shape({
          interests: PropTypes.array,
          shownItems: PropTypes.number,
        }),
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Interests
