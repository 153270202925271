import React from "react"
// import { motion, AnimatePresence } from "framer-motion"
// import styled from "styled-components"
import PropTypes from "prop-types"

const ContactForm = ({ config }) => {
  return <div className="contact-form">Form goes here.</div>
}

ContactForm.propTypes = {
  config: PropTypes.object,
}

export default ContactForm
