import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "styled-components"
import PropTypes from "prop-types"

const Underlining = styled.div`
  padding: 0 0;
  box-shadow: inset 0 -0.15em 0 ${({ theme }) => (theme.colors.light ? `#9fddfe` : `#0881b5`)};
  transition: box-shadow 0.1s ease-out, color 0.1s ease-out,
    padding 0.1s ease-out;
  &:hover {
    padding: 0 0.2em;
    box-shadow: inset 0 -3em 0 ${({ theme }) => (theme.colors.light ? `#9fddfe` : `#0881b5`)};
  }
`

const variants = {
  enter: direction => {
    return {
      y: "100%",
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    y: "0",
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      y: "-100%",
      opacity: 0,
    }
  },
}

const AnimatedUnderlining = motion.custom(Underlining)

const SubtitleCarousel = ({ subtitles }) => {
  const [currentSubtitle, setCurrentSubtitle] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      let current = subtitles[currentSubtitle]
      let index = subtitles.indexOf(current)
      if (index + 1 === subtitles.length) setCurrentSubtitle(0)
      else setCurrentSubtitle(index + 1)
    }, 2000)
  }, [subtitles, currentSubtitle])

  return (
    <div className="hero-subtitle-container">
      <AnimatePresence initial={false} custom={0}>
        <AnimatedUnderlining
          big
          key={currentSubtitle}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 500, damping: 30 },
            opacity: { duration: 0.1 },
          }}
          className="hero-subtitle"
        >
          {/* <span className="previous-subtitle">
            {subtitles[subtitleIndex - 1]}
          </span> */}
          {subtitles[currentSubtitle]}
        </AnimatedUnderlining>
      </AnimatePresence>
    </div>
  )
}

SubtitleCarousel.propTypes = {
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default SubtitleCarousel
