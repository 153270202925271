import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import Context from "../../context/"
import ContentWrapper from "../../styles/contentWrapper"
import Social from "../social"
import SubtitleCarousel from "../subtitleCarousel"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-bottom: 4rem;
    }
    .greetings {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .emoji {
      margin-left: 0.5rem;
      width: 1.75rem;
      height: 1.75rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 2.2rem;
        height: 2.2rem;
        margin-left: 0.75rem;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-left: 1rem;
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .title {
      margin-bottom: 0.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.micro}) {
        margin-bottom: 1rem;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-bottom: 0;
      }
    }
    .subtitle {
      margin-top: 0rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-top: 0.5rem;
      }
    }
    .description {
      font-size: 1.125rem;
      margin-bottom: 2rem;
      line-height: 1.8rem;
    }
    @media (max-width: ${({ theme }) =>
        `calc(${theme.breakpoints.sm} - 1px)`}) {
      .portrait,
      .portrait-container {
        max-width: 96px;
        min-width: 96px;
      }
      .hero-grid {
        grid-template-areas:
          "portrait"
          "text"
          "buttons";
        grid-template-columns: 100%;
      }
    }
  }
`

const Hero = ({ content }) => {
  const { frontmatter, body } = content[0].node
  const { isIntroDone, darkMode } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        eControls.start({
          rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
          transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
        })
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
        await sControls.start({
          opacity: 1,
          x: 0,
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, eControls, gControls, sControls])

  return (
    <StyledSection id="hero" className={darkMode ? "isdark" : ""}>
      <StyledContentWrapper>
        <div className="hero-grid">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={gControls}
            data-testid="animated-portrait"
            className="portrait-container"
          >
            <Img
              className="portrait"
              fluid={frontmatter.portraits.childImageSharp.fluid}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={gControls}
            data-testid="animated-heading"
          >
            <h1 className="title">
              <div className="greetings">
                {frontmatter.greetings}
                {frontmatter.greetingsName && (
                  <span className="greetings-name">
                    {frontmatter.greetingsName}
                  </span>
                )}
                {frontmatter.greetingsEnd}
                <motion.div
                  animate={eControls}
                  style={{ originX: 0.7, originY: 0.7 }}
                >
                  <Img
                    className="emoji"
                    fluid={frontmatter.icon.childImageSharp.fluid}
                  />
                </motion.div>
              </div>
              {frontmatter.title}
            </h1>
            <h2 className="subtitle">
              {frontmatter.subtitlePrefix}{" "}
              <SubtitleCarousel subtitles={frontmatter.subtitle} />
            </h2>
            <div className="description">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={sControls}
            className="social-buttons"
          >
            <Social fontSize=".95rem" padding=".3rem 1.25rem" width="auto" />
          </motion.div>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
