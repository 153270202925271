import React, { useRef, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import { useOnScreen } from "../../hooks/"
import Context from "../../context/"
import ContentWrapper from "../../styles/contentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 4rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: row;
      justify-content: space-between;
    }
    .section-title {
      margin-bottom: 2rem;
      text-align: center;
      @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
        text-align: left;
      }
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .text-content {
      width: 100%;
      max-width: 31.25rem;
    }
    .image-content {
      width: 100%;
      max-width: 18rem;
      margin-top: 4rem;
      margin-left: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left: 2rem;
      }
    }
    .about-author {
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
      filter: grayscale(20%) contrast(1) brightness(90%);
      transition: all 0.3s ease-out;
      &:hover {
        filter: grayscale(50%) contrast(1) brightness(90%);
        transform: translate3d(0px, -0.125rem, 0px);
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
      }
    }
  }
`

const StyledQualifications = styled.div`
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  max-width: 100%;
  flex-wrap: wrap;
  margin: 1.5rem 0;
  /* Workaround: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout */
  &::after {
    content: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.midMd}) {
      content: " ";
      flex-grow: 1000000;
      width: 1px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 15.625rem);
    overflow: visible;
  }
  .qualification {
    flex-grow: 1;
    width: fit-content;
    position: relative;
    /* max-width: 15rem; */
    /* min-height: 3rem; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem;
    // padding-right: calc(0.75rem * 1.5);
    /* border: 0.125rem solid ${({ theme }) => theme.colors.card}; */
    border-radius: ${({ theme }) => theme.borderRadius};
    background: ${({ theme }) => theme.colors.card};
    color: ${({ theme }) => theme.colors.primary};
    transition: background-position 0.05s ease-out, transform 0.1s ease-out;

    /* Gradient BG hover stuff - bottom-to-top, like underline element */
    /* background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.card} 50%,
      ${({ theme }) => theme.colors.cardHover} 50%
    );
    background-size: 100% 205%;
    background-position: top;
    &:hover {
      background-position: bottom;
      transform: translateY(-2px) !important;
    } */

    /* Gradient BG hover stuff - left-to-right, like the buttons */
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.cardHover} 50%,
      ${({ theme }) => theme.colors.card} 50%
    );
    background-size: 205% 100%;
    background-position: right bottom;
    &:hover {
      background-position: left bottom;
      transform: translateY(-2px) !important;
    }

    .qualification-title {
      font-size: 1.15em;
      font-weight: bold;
      padding-bottom: 0.25rem;
    }
    .qualification-major {
      padding-bottom: 0.5rem;
      font-style: italic;
    }
    .qualification-institution {
      opacity: 75%;
    }
    .qualification-timeframe {
      opacity: 75%;
    }
  }
`

const About = ({ content }) => {
  const { frontmatter, body, exports } = content[0].node
  const { qualifications } = exports

  const { isIntroDone } = useContext(Context).state
  const tControls = useAnimation()
  const iControls = useAnimation()

  // Required for animating the text content
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)

  // Required for animating the image
  const iRef = useRef()
  const iOnScreen = useOnScreen(iRef)

  // Only trigger animations if the intro is done or disabled
  useEffect(() => {
    if (isIntroDone) {
      if (tOnScreen) tControls.start({ opacity: 1, y: 0 })
      if (iOnScreen) iControls.start({ opacity: 1, x: 0 })
    }
  }, [isIntroDone, tControls, iControls, tOnScreen, iOnScreen])

  useEffect(() => {
    const sequence = async () => {
      if (tOnScreen) {
        // i receives the value of the custom prop - can be used to stagger
        // the animation of each "interest" element
        await iControls.start(i => ({
          y: 0,
          opacity: 1,
          transition: { delay: i * 0.02 },
        }))
      }
    }
    sequence()
  }, [tOnScreen, iControls])

  return (
    <StyledSection id="about">
      <StyledContentWrapper>
        <motion.div
          className="inner-wrapper"
          ref={tRef}
          initial={{ opacity: 0, y: 20 }}
          animate={tControls}
        >
          <h3 className="section-title">{frontmatter.title}</h3>
          <div className="text-content">
            <MDXRenderer>{body}</MDXRenderer>
          </div>
          <StyledQualifications itemCount={qualifications.length}>
            {qualifications.map(
              ({ qualification, major, institution, timeframe }, key) => (
                <motion.div
                  className="qualification"
                  key={key}
                  custom={key}
                  initial={{ opacity: 0, y: -10 }}
                  animate={iControls}
                >
                  <div className="qualification-title">{qualification}</div>
                  <div className="qualification-major">{major}</div>
                  <div className="qualification-institution">{institution}</div>
                  <div className="qualification-timeframe">{timeframe}</div>
                </motion.div>
              )
            )}
          </StyledQualifications>
        </motion.div>

        {/* {frontmatter.image.childImageSharp && (
          <motion.div
            className="image-content"
            ref={iRef}
            initial={{ opacity: 0, x: 20 }}
            animate={iControls}
          >
            <Img
              className="about-author"
              fluid={frontmatter.image.childImageSharp.fluid}
            />
          </motion.div>
        )} */}
      </StyledContentWrapper>
    </StyledSection>
  )
}

About.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
        exports: PropTypes.shape({
          qualifications: PropTypes.arrayOf(
            PropTypes.shape({
              qualification: PropTypes.string.isRequired,
              major: PropTypes.string.isRequired,
              institution: PropTypes.string.isRequired,
              timeframe: PropTypes.string.isRequired,
            }).isRequired
          ).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default About
